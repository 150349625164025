import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { FailedImportData } from './types';
import { ListActions } from 'components/List';
import { DeleteErrorQueueModal } from './DeleteErrorQueueModal';
import { useDrawer } from 'components/DrawerContainer';
import { routes } from 'navigator';
import { MatchTemplateForm } from '../MatchTemplate/MatchTemplateForm';

export const ErrorQueueListItemEdit = ({ data }: Props) => {
    const [deleteOpen, setDeleteOpen] = useState(false);
    const { openDrawer } = useDrawer();
    const navigate = useNavigate();

    const handleDeleteClick = () => {
        setDeleteOpen(true);
    };

    const handleViewClick = () => {
        navigate(`${routes.documents}/${data.id}`);
    };
    const handleFixClick = async () => {
        if (data.typeId && data.fixMsg?.toLowerCase().includes('integration entity action')) {
            navigate(`${routes.templates}`, { state: { typeId: data.typeId } });
            return;
        }

        if (data.fixMsg?.toLowerCase().includes('system settings')) {
            navigate(`${routes.systemSettings}`, { state: 'fix' });
            return;
        }

        switch (data.failStatus) {
            case 'TYPE_MATCH':
                openDrawer(
                    'Set template',
                    <MatchTemplateForm documentId={data.id} showDocImg={true} />
                );
                break;
            case 'IMPORT':
            case 'VALIDATION':
                navigate(`${routes.documents}/${data.id}`);
                break;
            default:
                break;
        }
    };

    return (
        <>
            <ListActions
                onDeleteClick={handleDeleteClick}
                onFixClick={handleFixClick}
                onViewClick={handleViewClick}
                isView={data.failStatus != 'TYPE_MATCH'}
            />
            <DeleteErrorQueueModal open={deleteOpen} setOpen={setDeleteOpen} selected={[data]} />
        </>
    );
};

interface Props {
    data: FailedImportData;
}
